import storage from 'redux-persist/lib/storage';
import { toast } from 'sonner';

import {
  API_BASE_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
} from '@/old/config/api_routes';
import {
  CLOSE_FORGOT_PASSWORD,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  RESET_SESSION_DATA,
  SET_CURRENT_PATIENT_ID,
  STORE_SESSION_DATA,
} from '@/old/state/actions/types';
import { shutdownIntercom } from '@/old/utils/thirdPartyAPIs/intercom';
import { LOGIN_URL, PATIENT_LIST_URL } from '@/routes/app-routes';
import { initAuthState } from '@/utils/auth-utils';
import { ResponseError, sendWithRetry } from '@/utils/fetch-utils';

export const setLoginPending = (loginPending) => {
  return {
    type: LOGIN_PENDING,
    payload: { loginPending },
  };
};

export const setLoginSuccess = (loginSucces) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      loginSucces,
      loggedIn: true,
      username: loginSucces.username,
      user_type: loginSucces.user_type,
    },
  };
};

export const closeForgotPassword = () => {
  return {
    type: CLOSE_FORGOT_PASSWORD,
    payload: { open: false },
  };
};
export const resetSessionData = () => {
  return {
    type: RESET_SESSION_DATA,
    payload: {},
  };
};
export const storeSessionData = (data) => {
  return {
    type: STORE_SESSION_DATA,
    payload: { data },
  };
};

export const setCurrentPatient = (currentPatientId) => {
  return {
    type: SET_CURRENT_PATIENT_ID,
    payload: { currentPatientId },
  };
};
export const clearState = () => {
  return {
    type: 'CLEAR_STATE',
    payload: {},
  };
};

export const login = (navigate, username, password) => {
  return async (dispatch) => {
    try {
      const data = await sendWithRetry(`${API_BASE_URL}${API_LOGIN_URL}`, {
        // use below when gql is ready
        // username: username.toLowerCase(), // can provide username or email
        username: username.toLowerCase(), // can provide username or email despite having to say username
        password,
      });

      initAuthState(dispatch, data);

      navigate(PATIENT_LIST_URL);
    } catch (error) {
      if (error instanceof ResponseError && error.response.status === 401) {
        toast.warning(
          'Wrong username or password. Try again or click Forgot password to reset it.',
        );
        return error.response;
      }

      toast.warning(
        'Network error, please try again later or contact support.',
      );

      return error;
    } finally {
    }
  };
};

export const logout = (wasInactive = false) => {
  return async (dispatch) => {
    try {
      shutdownIntercom();

      dispatch(resetSessionData()); // this will remove token and trigger redirection
      dispatch(clearState());
      clearLocalStorage();

      storage.removeItem('persist:root:PatientAssignments');

      await sendWithRetry(`${API_BASE_URL}${API_LOGOUT_URL}/`, {});

      if (wasInactive) {
        toast.info('Logged out due to inactivity');
      } else {
        toast.success('Logout Successful');
      }

      history.pushState({}, '', LOGIN_URL);
      location.reload();
    } catch (error) {
      toast.error('Logout failed. Try again later.');

      return error;
    }
  };
};

/** Keys not cleared during logout. */
const PERSISTED_KEYS = ['theme'];

/** Clear any non-persisted keys from local storage. */
function clearLocalStorage() {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!PERSISTED_KEYS.includes(key)) {
      localStorage.removeItem(key);
    }
  }
}
